@import "../../scss/main.scss";

.wrapper {
  border-bottom: 1px solid $shadow;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  gap: 10px;

  &:hover {
    background: rgb(250, 250, 250);
  }

  & .avatarWrapper {
    min-width: 290px;
    max-width: 350px;
    display: flex;
    align-items: center;
    gap: 15px;
    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
      object-fit: cover;
    }

    & .name {
      @include text_medium_bold;
    }

    & .email {
      @include text_small_regular;
    }
  }

  & .typeWrapper {
    flex: 0.5;
    max-width: 100px;
  }
  & .categoryWrapper {
    flex: 2;
    margin-right: 30px;

    & p {
      @include text_small_bold;
    }

    & .subtitle {
      @include text_small_regular;
      margin-top: 5px;
    }
  }

  & .readWrapper {
    flex: 0.3;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  & .reportedWrapper {
    flex: 0.5;
    max-width: 100px;
    display: flex;
    align-items: center;
    gap: 10px;

    & .reportedAvatar {
      width: 32px;
      height: 32px;
      border-radius: 100px;
    }

    & p {
      @include text_small_bold;
    }
  }
  & .dateWrapper {
    flex: 0.5;
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      @include text_small_regular;
    }

    & .arrowBtn {
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        transform: rotate(-90deg);
      }
    }
  }
}
