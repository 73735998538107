@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid $shadow;

  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background: $grayE430;
  }

  & .nameBlock {
    flex: 0.6;
    display: flex;
    align-items: center;

    & .avatarWrapper {
      min-width: 44px;
      height: 44px;
      border-radius: 100px;
      background: $grayMedium;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      & svg {
        width: 20px;
        height: 20px;
        fill: $grayDark;
      }

      & .avatar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    & .name {
      @include text_medium_bold;
      margin-left: 15px;
      word-break: break-all;
      word-wrap: break-word;
      margin-right: 10px;
    }
  }

  & .genderBlock {
    flex: 0.2;
    display: flex;
    align-items: center;

    & .gender {
      @include text_small_regular;
    }
  }

  & .subscriptionBlock {
    flex: 0.32;
    display: flex;
    align-items: center;

    & .subscription {
      @include text_small_regular;
    }
  }

  & .deficitBlock {
    flex: 0.5;

    & .deficit {
      @include text_small_regular;
    }
  }

  & .dateBlock {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .date {
      @include text_small_regular;
    }

    & .arrowButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      transform: rotate(-90deg);
    }
  }
}
