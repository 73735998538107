@import "../../scss/main.scss";

.wrapper {
  padding: 3px 10px 4px 10px;
  border-radius: 8px;
  width: max-content;

  & span {
    @include text_x_small_bold;
    color: $white;
  }
}

.problemWrapper {
  background: $deactive;
}

.userWrapper {
  background: #ff754c;
}

.positiveWrapper {
  background: #7fba7a;
}

.negativeWrapper {
  background: #ff2950;
}

.neutralWrapper {
  background: #fb8a00;
}
