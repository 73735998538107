@import "../../scss/main.scss";

.wrapper {
  position: fixed;
  width: 256px;
  height: 100vh;
  padding: 20px;
  border-right: 2px solid $shadow;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: space-between;
  & .logo {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    width: 147px;
    height: 37px;
    object-fit: contain;
  }

  & .profileContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 15px;
    cursor: pointer;
    position: relative;
    margin-top: 10px;

    & .menu {
      left: 0;
      right: 0;
      bottom: 50px;
      padding: 10px;
      background: #ffffff;
      box-shadow: 0px 15px 80px rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      position: absolute;
    }

    & .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      object-fit: cover;
    }

    & .infoContainer {
      margin-left: 16px;

      & .name {
        @include text_small_bold;
        color: $dark;
      }

      & .role {
        @include text_x_small_regular;
        color: $deactive;
      }
    }
  }

  & .logoutBtn {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    gap: 14px;
    border-radius: 10px;
    @include text_small_bold;
    transition: all 0.2s linear;

    & svg {
      fill: $primary;
    }
    &:hover {
      background: rgba(228, 228, 228, 0.25);
    }
  }

  & .buttonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 2px;

    & .linkBtn {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 18px 10px 18px 20px;
      border-radius: 12px;
      transition: all 0.2s linear;

      &:hover {
        background: $primaryLight;
      }

      &[class*="active"] {
        background: $primary;

        &:hover {
          opacity: 0.95;
        }
        & svg {
          fill: $white;
        }

        & p {
          color: $white;
        }
      }

      & svg {
        fill: $grayLight ;
      }

      & p {
        @include text_small_bold;
        color: $grayLight;
      }

      & .unreadIndicator {
        width: 6px;
        height: 6px;
        border-radius: 100px;
        background: $red;
        margin-left: -5px;
      }
    }

    & .dropdownLink {
      margin-top: 2px;
      &[class*="active"] {
        background: $primaryLight;

        & p {
          color: $primary;
        }
      }
    }
  }
}
